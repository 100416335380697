
// Stylesheets
import "./theme.scss";
import "./resources/images/favicon.png";

// Component Javascript
import "./components/experiencefragment/change_flag.js";
import "./components/form-options/form_dropdown.js";
import "./components/progressbar/progressbar.js";
import "./components/productfinder/productFinder.js";
