document.addEventListener("DOMContentLoaded", () => {
  setTimeout(() => {
    const productFinder = document.querySelector(".productfinder");
    if (productFinder) {
      // Select the input by its unique ID
      const productOption = document.querySelector("#WTXC1");
      productOption?.click();
    }
  }, 800);
});
